import './Game.css'
import { useEffect, useState } from 'react';
import { Dialog, TextField, ToggleButton } from '@mui/material';
import { generateNonRepeatingNumber, generateNumberAllowsRepeating } from '../../utils/random-generator.util';
import { CircleColor } from '../../enums/circle-color.enum';
import GameOverModal from '../GameOverModal/GameOverModal';
import SettingsIcon from '@mui/icons-material/Settings';
import ReplayIcon from '@mui/icons-material/Replay';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingsModal from '../SettingsModal/SettingsModal';
import { Level } from '../../enums/level.enum';
import HelpModal from '../HelpModal/HelpModal';
import ResetGameModal from '../ResetGameModal/ResetGameModal';
import { ModalAction } from '../../enums/modal-action.enum';
import { LocalStorageKey } from '../../enums/local-storage-key.enum';
import NumberPad from '../NumberPad/NumberPad';
import { Edit } from '@mui/icons-material';
import { InputView } from '../../enums/input-view.enum';
import { CheckedGuess } from '../../interfaces/checked-guess.interface';
import { KeyInput } from '../../enums/key-input.enum';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const TEXTAREA_TAG_NAME = 'TEXTAREA';
const KEYDOWN_EVENT = 'keydown';
const BACKDROP_CLICK_REASON = 'backdropClick';

function Game() {
  if (!localStorage.getItem(LocalStorageKey.SELECTED_LEVEL)) {
    localStorage.setItem(LocalStorageKey.SELECTED_LEVEL, Level.NORMAL);
  }

  if (!localStorage.getItem(LocalStorageKey.IS_COLOR_BLIND_FRIENDLY)) {
    localStorage.setItem(LocalStorageKey.IS_COLOR_BLIND_FRIENDLY, 'false');
  }

  const [solution, setSolution] = useState(getSolution());
  const [numDigits, setNumDigits] = useState(getNumDigits());
  const [numChances, setNumChances] = useState(getNumChances());
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [currentColIndex, setCurrentColIndex] = useState(0);
  const [guesses, setGuesses] = useState(getEmptyGuessGrid());
  const [checkedGuesses, setCheckedGuesses] = useState(getEmptyCheckedGuesses);
  const [notes, setNotes] = useState('');

  const [isNotePadOpen, setIsNotePadOpen] = useState(false)
  const [isGameOverDialogOpen, setIsGameOverDialogOpen] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [isColorBlindFriendly, setIsColorBlindFriendly] = useState(localStorage.getItem(LocalStorageKey.IS_COLOR_BLIND_FRIENDLY) === 'true');
  const [isGameOver, setIsGameOver] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isInitialHelpModalOpen, setIsInitialHelpModalOpen] = useState(false);
  const [isResetGameModalOpen, setIsResetGameModalOpen] = useState(false);


  useEffect(() => {
    const shouldShowHelpModal = localStorage.getItem(LocalStorageKey.SHOULD_SHOW_HELP_MODAL);

    if (!shouldShowHelpModal) {
      localStorage.setItem(LocalStorageKey.SHOULD_SHOW_HELP_MODAL, 'true')
    }

    if (shouldShowHelpModal !== 'false') {
      setIsHelpModalOpen(true);
      setIsInitialHelpModalOpen(true);
    }
  }, []);

  const updateAtIndex = (value: string, index: number) => {
    const currentGuess = guesses[currentRowIndex].map((num, i) => {
      if (i === index) {
        return value;
      } else {
        return num;
      }
    }); 

    return guesses.map((guess, i) => {
      if (i === currentRowIndex) {
        return currentGuess;
      } else {
        return guess;
      }
    });
  }

  const handleNumberSelection = (selectedNumber: string) => {
    if (currentColIndex >= numDigits) {
      return;
    }
  
    const newGuesses = updateAtIndex(selectedNumber, currentColIndex);
    setGuesses(newGuesses);
    setCurrentColIndex(currentColIndex + 1);
  }

  const handleNumberDeletion = () => {
    if (currentColIndex > 0) {
      setGuesses(updateAtIndex('', currentColIndex - 1));
      setCurrentColIndex(currentColIndex - 1);
    }
  }

  const handleNumberSubmit = () => {
    if (currentColIndex === numDigits) {
      const result = checkGuess();
      if (result.numBlue === numDigits || currentRowIndex === numChances - 1) {
        const hasWon = result.numBlue === numDigits;
        setIsWinner(hasWon);
        setIsGameOver(true);
        setIsGameOverDialogOpen(true);
        updateStatistics(hasWon);
        document.removeEventListener(KEYDOWN_EVENT, handleKeyDown);
      }
      
      setCurrentRowIndex(currentRowIndex + 1);
      setCurrentColIndex(0); 
    }
  }

  const handleKeyDown = (event) => {
    if (document.activeElement.tagName === TEXTAREA_TAG_NAME) {
      return;
    }

    if (event.key === KeyInput.BACKSPACE) {
      handleNumberDeletion();
    } else if (event.key === KeyInput.ENTER) {
      handleNumberSubmit();
    } else if (event.key.match(/[^0-9]/)) {
      return;
    } else {
      handleNumberSelection(event.key);
    }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  }

  const handleGameOverDialogClose = (event, reason) => {
    if (reason !== BACKDROP_CLICK_REASON) {
      setIsGameOverDialogOpen(false);
    }
  };

  const handleSettingsModalOpen = () => {
    setIsSettingsModalOpen(true);
  }

  const handleSettingsModalClose = () => {
    setIsSettingsModalOpen(false);
  }

  const handleHelpModalOpen = () => {
    setIsHelpModalOpen(true);
    setIsInitialHelpModalOpen(false);
  }

  const handleHelpModalClose = () => {
    setIsHelpModalOpen(false);
    setIsInitialHelpModalOpen(false);
  }

  const handleGameReset = () => {
    setIsGameOverDialogOpen(false);
    setSolution(getSolution());
    setCurrentRowIndex(0);
    setCurrentColIndex(0);
    setGuesses(getEmptyGuessGrid());
    setCheckedGuesses(getEmptyCheckedGuesses());
    setNotes('');
    document.addEventListener(KEYDOWN_EVENT, handleKeyDown);
    setIsWinner(false);
    setIsGameOver(false);
  }

  const handleLevelChange = () => {
    setNumDigits(getNumDigits());
    setNumChances(getNumChances());
    handleGameReset();
  }

  const handleColorBlindFriendlyChange = (value) => {
    setIsColorBlindFriendly(value);
  }

  const updateStatistics = (isWinner: boolean) => {
    const statisticsStorage = localStorage.getItem(LocalStorageKey.STATISTICS);
    const winStreakStorage = localStorage.getItem(LocalStorageKey.WIN_STREAK);
    const selectedLevel = localStorage.getItem(LocalStorageKey.SELECTED_LEVEL);

    let statistics = {
      easy: {
        numGames: 0,
        numWon: 0
      },
      normal: {
        numGames: 0,
        numWon: 0
      },
      hard: {
        numGames: 0,
        numWon: 0
      }
    };

    let winStreak = 0;

    if (statisticsStorage) {
      statistics = JSON.parse(statisticsStorage);
    }

    if (winStreakStorage) {
      winStreak = parseInt(winStreakStorage);
    }
    
    statistics[selectedLevel].numGames++;
    if (isWinner) {
      statistics[selectedLevel].numWon++;
      winStreak++;
    } else {
      winStreak = 0;
    }

    localStorage.setItem(LocalStorageKey.STATISTICS, JSON.stringify(statistics));
    localStorage.setItem(LocalStorageKey.WIN_STREAK, winStreak.toString());
  }

  const handleResetGameModalOpen = () => {
    if (isGameOver) {
      handleGameReset();
    } else if (guesses[0][numDigits - 1].length) {
      setIsResetGameModalOpen(true);
    } else if (guesses[0][0].length) {
      setCurrentRowIndex(0);
      setCurrentColIndex(0);
      setGuesses(getEmptyGuessGrid());
      setNotes('');
    }
  }

  const handleResetGameModalClose = (action: ModalAction) => {
    if (action === ModalAction.ACTION) {
      updateStatistics(false);
      handleGameReset();
    }
    setIsResetGameModalOpen(false);
  }

  const checkGuess = () => {
    const guess = guesses[currentRowIndex];
    if (guess.includes('')) {
      return null;
    }
  
    let numBlue = 0;
    let numPink = 0;
    let remainingGuessNums = [];
    let remainingSolutionNums = [];
  
    // check for exact matches
    guess.forEach((num, index) => {
      if (num === solution[index]) {
        numBlue++
      } else {
        remainingGuessNums.push(num);
        remainingSolutionNums.push(solution[index]);
      }
    });
  
    // check for other included numbers
    remainingGuessNums.forEach((num) => {
      if (remainingSolutionNums.includes(num)) {
        numPink++;
        const numIndex = remainingSolutionNums.findIndex((value) => value === num);
        remainingSolutionNums.splice(numIndex, 1);
      }
    });

    const newCheckedGuesses = checkedGuesses.map((checkedGuess, index) => {
      if (index === currentRowIndex) {
        return { numBlue, numPink }
      } else {
        return checkedGuess;
      }
    });
    setCheckedGuesses(newCheckedGuesses);
    return { numBlue, numPink };
  }

  useEffect(() => {
    if (!isGameOver) {
      document.addEventListener(KEYDOWN_EVENT, handleKeyDown);
    }
    
    return () => {
      document.removeEventListener(KEYDOWN_EVENT, handleKeyDown);
    };
  });

  return (
    <div>
      <div className='header'>
        <span className='placeholder'></span>
        <span className='title'>O-Numbo</span>

        <div className='menu-btns'>
            <SettingsIcon className='action-btn' onClick={handleSettingsModalOpen}/>
            <QuestionMarkIcon className='action-btn' onClick={handleHelpModalOpen}/>
            <ReplayIcon className='action-btn' onClick={handleResetGameModalOpen}/>
          </div>
      </div>
      
      
      <div className='game'>
        {
          guesses.map((guess, index) => {
            const { numBlue, numPink } = checkedGuesses[index];
            const hasNoResults = numBlue + numPink === 0 && isGuessSubmitted(index, guesses, currentRowIndex);

            return (
              <div key={`row-${index}`} className='row'>
                <div className={getResultClassName(index, hasNoResults)}>
                  { generateCircles(CircleColor.BLUE, numBlue, isColorBlindFriendly) }
                  { generateCircles(CircleColor.PINK, numPink, isColorBlindFriendly) }
                  { hasNoResults && <SentimentDissatisfiedIcon className='no-results-icon'/> }
                </div>
                <div className={getGuessClassName(numDigits)}>
                  {
                    guess.map((char, i) => {
                      return (
                        <div key={`col-${i}`} className={getColumnClassName(char)}>
                          <div className='num'>{ char }</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      <div className='selected-views'>
        {
          isNotePadOpen && <div className='notes'>
            <TextField
              value={notes}
              onChange={handleNotesChange}
              className='notes-edit'
              placeholder='Notes...'
              multiline
              rows={6}
            />
          </div>
        }
        <NumberPad
            isNotePadOpen={isNotePadOpen}
            handleNumberSelection={handleNumberSelection}
            handleNumberDeletion={handleNumberDeletion}
            handleNumberSubmit={handleNumberSubmit}
          />
      </div>
      <div className='selected-views-toggle'>
        <ToggleButton
          className='selected-views-toggle-btn'
          color='primary'
          value={InputView.NOTE_PAD}
          selected={isNotePadOpen}
          onChange={() => setIsNotePadOpen(!isNotePadOpen)}
        >
          <Edit/>
        </ToggleButton>
      </div>
      
      <Dialog id='game-over-dialog' className='game-over-dialog' onClose={handleGameOverDialogClose} open={isGameOverDialogOpen}>
        <GameOverModal
          solution={solution}
          handleClose={(event, reason) => handleGameOverDialogClose(event, reason)}
          isWinner={isWinner}
          handleGameReset={handleGameReset}
          isOpen={isGameOverDialogOpen}
        />
      </Dialog>
      <Dialog open={isSettingsModalOpen} onClose={handleSettingsModalClose}>
        <SettingsModal
          handleClose={handleSettingsModalClose}
          handleLevelChange={handleLevelChange}
          handleColorBlindFriendlyChange={handleColorBlindFriendlyChange}
        />
      </Dialog>
      <HelpModal
        isHelpModalOpen={isHelpModalOpen}
        handleClose={handleHelpModalClose}
        isColorBlindFriendly={isColorBlindFriendly}
        isInitialOpen={isInitialHelpModalOpen}
      />
      <Dialog open={isResetGameModalOpen} onClose={handleResetGameModalClose}>
        <ResetGameModal handleClose={(action: ModalAction) => handleResetGameModalClose(action)}/>
      </Dialog>
    </div>
  );
}

export default Game;

/**
 * Helper Methods
 */

const getNumDigits = () => {
  const selectedLevel = localStorage.getItem(LocalStorageKey.SELECTED_LEVEL);

  switch (selectedLevel) {
    case Level.EASY:
      return 3;
    case Level.NORMAL:
      return 4;
    case Level.HARD:
      return 4;
  }
}

const getNumChances = () => {
  const selectedLevel = localStorage.getItem(LocalStorageKey.SELECTED_LEVEL);

  switch (selectedLevel) {
    case Level.EASY:
      return 6;
    case Level.NORMAL:
      return 6;
    case Level.HARD:
      return 6;
  }
}

const getSolution = () => {
  const selectedLevel = localStorage.getItem(LocalStorageKey.SELECTED_LEVEL);
  const numDigits = getNumDigits();

  if (selectedLevel === Level.EASY || selectedLevel === Level.NORMAL) {
    return generateNonRepeatingNumber(numDigits);
  } else if (selectedLevel === Level.HARD) {
    return generateNumberAllowsRepeating(numDigits);
  }
};

const getEmptyGuessGrid = (): string[][] => {
  const numDigits = getNumDigits();
  const numChances = getNumChances();

  const guesses = [];
  for (let i = 0; i < numChances; i++) {
    const guess = [];
    for (let j = 0; j < numDigits; j++) {
      guess.push('');
    }
    guesses.push(guess);
  }

  return guesses;
};

const getEmptyCheckedGuesses = (): CheckedGuess[] => {
  const checkedGuesses = [];
  const numChances = getNumChances();
  for (let i = 0; i < numChances; i++) {
    checkedGuesses.push({
      numBlue: 0,
      numPink: 0
    });
  }
  return checkedGuesses;
};

const isGuessSubmitted = (index: number, guesses: string[][], currentRowIndex: number) => {
  if (currentRowIndex <= index) {
    return false;
  }

  const guess = guesses[index];
  const numGuessDigits = guess.filter(digit => digit.length === 1)?.length ?? 0;

  if (numGuessDigits === getNumDigits()) {
    return true;
  }

  return false;
}

const generateCircles = (color: CircleColor, numCircles: number, isColorBlindFriendly: boolean) => {
  if (!numCircles) {
    return null;
  }
  const circles = [];

  for (let i = 0; i < numCircles; i++) {
    circles.push((
      <div className={`${color}-circle`} key={`${color}-${i}`}>
        {isColorBlindFriendly ? color.charAt(0).toUpperCase() : ''}
      </div>
    ));
  }

  return (
    <div className='circles'>{ circles }</div>
  )
}

const getGuessClassName = (numDigits: number) => {
  return `guess guess-${numDigits}`;
}

const getResultClassName = (index: number, hasNoResults: boolean) => {
  let className = 'result';
  if (index === 0) {
    className += ' result-first';
  } else if (index === getNumChances() - 1) {
    className += ' result-last'
  }

  if (hasNoResults) {
    className += ' no-results'
  }

  return className;
}

const getColumnClassName = (char: string) => {
  let className = 'column';

  if (char !== '') {
    className += ' column-selected';
  }

  return className;
}